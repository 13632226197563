import { defineStore } from 'pinia'

export const useSearchSmpEncashmentOrganisationStore = defineStore('searchSmpEncashmentOrganisationStore', {
  state: () => {
    return {
      SmpEncashmentEntityIds: [] as number[],
      SmpEncashmentEntityFilter: 'NOT_ALLOCATED' as string | null,
      SmpContribution: null as string | null,
      SmpEncashment_date: null as string | null
    }
  }
})
