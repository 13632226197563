<template>
  <div>
    <h2 v-html="$t('search_smp_encashment_card_title')"></h2>
    <v-select
      id="search_smp_encashment_card_organisations"
      v-model="smpEncashmentOrganisationStore.SmpEncashmentEntityIds"
      :label="$t('search_smp_encashment_card_organisations')"
      :items="smpEncashmentEntities"
      :item-title="entityName"
      item-value="id"
      clearable
      @keyup.enter="emitSearch"
      multiple
    />
    <v-select
      id="search_smp_encashment_card_assigned"
      v-model="smpEncashmentOrganisationStore.SmpEncashmentEntityFilter"
      :label="$t('search_smp_encashment_card_assigned')"
      :items="smpEncashmentEntityFilter"
      :item-title="['name' + $route.params.upLang]"
      item-value="value"
      clearable
      @keyup.enter="emitSearch"
    />
    <v-select
      id="search_smp_encashment_card_smp_fee"
      v-model="smpEncashmentOrganisationStore.SmpContribution"
      :label="$t('search_smp_encashment_card_smp_fee')"
      :items="smpFee"
      :item-title="['name' + $route.params.upLang]"
      item-value="value"
      clearable
      @keyup.enter="emitSearch"
    />
    <dbmDatePicker v-model="smpEncashmentOrganisationStore.SmpEncashment_date" :date-label="$t('search_smp_encashment_card_deadline')" :clearable="true" />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useSearchSmpEncashmentOrganisationStore } from '@/store/SearchSmpEncashmentsStore'
import { useSmpEncashmentEntityStore } from '@/store/enums/SmpEncashmentEntityStore'

/////  search card

const emit = defineEmits(['search'])

const emitSearch = () => {
  emit('search')
}

/////  smpEncashmentOrganisationStore store

const smpEncashmentOrganisationStore = useSearchSmpEncashmentOrganisationStore()

/////  enumsSmpEncashment_date

const smpEncashmentEntityStore = useSmpEncashmentEntityStore()

interface SmpEnchashmentEntity {
  ident: number
  name: string
  displayName?: string
}

const smpEncashmentEntityFilter = computed(() => {
  return [
    { id: 1, value: 'ALLOCATED', nameDe: 'Zugeteilt', nameFr: 'Attribué', nameIt: 'Assegnato' },
    { id: 2, value: 'NOT_ALLOCATED', nameDe: 'Zuteilung fehlt', nameFr: 'Non attribué', nameIt: 'Non assegnato' }
  ]
})

const smpFee = computed(() => {
  return [
    { id: 1, value: 'yes', nameDe: 'ja', nameFr: 'oui', nameIt: 'sì' },
    { id: 2, value: 'no', nameDe: 'nein', nameFr: 'non', nameIt: 'no' },
    { id: 3, value: 'voluntary', nameDe: 'freiwillig', nameFr: 'volontaire', nameIt: 'volontario' },
    { id: 4, value: 'exempted', nameDe: 'ausgenommen', nameFr: 'exempt', nameIt: 'eccetto' }
  ]
})

const smpEncashmentEntities = computed<SmpEnchashmentEntity[]>(() => {
  return smpEncashmentEntityStore.smpEncashmentEntities.map((org: SmpEnchashmentEntity) => ({
    ...org,
    displayName: `${org.ident} ${org.name} `
  }))
})

const entityName = (item: SmpEnchashmentEntity) => {
  return item.displayName || item.name
}

onBeforeMount(() => {
  smpEncashmentEntityStore.fetchSmpEncashmentEntities()
})
</script>
